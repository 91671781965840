// extracted by mini-css-extract-plugin
export var categories = "_404-module--categories--6c720";
export var categoriesText = "_404-module--categoriesText--18ce1";
export var content = "_404-module--content--59018";
export var date = "_404-module--date--ea4e2";
export var errorHeading = "_404-module--errorHeading--75ddb";
export var page = "_404-module--page--ec446";
export var pageHeading = "_404-module--pageHeading--071eb";
export var pages = "_404-module--pages--4c65c";
export var pagesOuter = "_404-module--pagesOuter--51b09";
export var sectionA = "_404-module--sectionA--26074";
export var tabs = "_404-module--tabs--007f6";
export var text = "_404-module--text--4c273";