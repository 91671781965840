import { graphql, Link, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import Col from "react-bootstrap/Col"
import Dropdown from "react-bootstrap/Dropdown"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import NavLink from "react-bootstrap/NavLink"
import Row from "react-bootstrap/Row"
import caretDown from "../../images/homePage/navDrop.svg"
import arrow from "../../images/navbar/arrow.svg"
import arrow2 from "../../images/navbar/arrowB.svg"
import bg1 from "../../images/navbar/invoblox-bg-desktop.svg"
import bg from "../../images/navbar/invogames-bg-desktop.svg"
import bg2 from "../../images/navbar/invoteams-bg-desktop.svg"
import { isValidHttpUrl } from "../../utils/index"
import useWindowDimensions from "../hooks/useWindowDimensions"
import * as styles from "./Header.module.scss"
import MobileHeader from "./mobileHeader"
import "./navbar.scss"

const name = [
  {
    title: `Game Development Services`,
    button: `Visit Website`,
    link: `https://invogames.com/`,
    image2: arrow,
    image: bg,
  },
  {
    title: `Blockchain Development Services`,
    image: bg1,
    link: `https://invoblox.com/`,
    button: `Visit Website`,
    image2: arrow,
  },
  {
    title: `Connect With Premium Professionals`,
    image: bg2,
    button: `Visit Website`,
    link: `https://invoteams.com/`,
    image2: arrow2,
  },
]

const Header = ({ bgChanged = true, ppcPageHeader }) => {
  const [navExpanded, setNavExpanded] = useState(false)
  const [renderMenu, setRenderMenu] = useState(false)
  const { width } = useWindowDimensions()
  const data = useStaticQuery(graphql`
    query getMainMenuItems {
      allStrapiMenu {
        nodes {
          menus {
            title
            type
            url
            button {
              title
              url
            }
            sub_menu {
              title
              url
              icon {
                url
              }
            }
          }
        }
      }
    }
  `)

  const { menus: items } = data?.allStrapiMenu?.nodes[0]
  const [transparent, setTransparent] = useState(true)
  const isMobile = width <= 720

  const handleScroll = () => {
    !isMobile && setNavExpanded(false)
    if (window.scrollY < 77) {
      return setTransparent(true)
    } else if (window.scrollY > 77) {
      return setTransparent(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  useEffect(() => {
    setRenderMenu(false)
  }, [])

  const clickHandler = () => {
    setRenderMenu(!renderMenu)
    // setAnimate(renderMenu ? false : title)
  }

  const hoverHandler = () => {
    if (isMobile) {
      return setRenderMenu(false)
    }
    setRenderMenu(true)
    // setAnimate(title)
  }

  return (
    <header>
      <div className={styles.navTop}>
        <Navbar
          bg={`${transparent && bgChanged && !navExpanded ? "" : "primary"} `}
          expand="lg"
          onToggle={() => setNavExpanded(!navExpanded)}
          expanded={navExpanded}
          style={{ padding: "0 10px 10px 10px" }}
        >
          <div
            className={styles.navBg}
            bg={`${transparent && bgChanged && !navExpanded ? "" : "primary"} `}
          ></div>
          <div className={styles.headerContainer}>
            <Navbar.Brand
              as={Link}
              to={`${ppcPageHeader ? "/" : "/hire-developers/contact-us/"}`}
              className={`w-auto`}
            >
              <StaticImage
                src="../../images/InvoZone_Logo.svg"
                alt="InvoZone"
                placeholder="blurred"
                layout="fixed"
                width={170}
                loading="eager"
                fetchpriority="high"
              />
            </Navbar.Brand>
            {ppcPageHeader ? (
              width > 1100 ? (
                <React.Fragment>
                  <Navbar.Collapse id="main-navbar-nav">
                    <Nav className="ml-auto">
                      {data?.allStrapiMenu?.nodes[0]?.menus?.map(
                        (item, index) => {
                          const { title, url, type, sub_menu: subItems, button } = item // prettier-ignore
                          if (type === "none") {
                            return (
                              <React.Fragment key={index}>
                                {isValidHttpUrl(url) ? (
                                  <Nav.Link
                                    as={Link}
                                    href={url}
                                    key={`${title}-nv`}
                                    id={`header_${url}_btn_gt`}
                                  >
                                    {title}
                                  </Nav.Link>
                                ) : (
                                  <Nav.Link
                                    as={Link}
                                    to={`/${url}/`}
                                    key={`${title}-nv`}
                                    id={`header_${url}_btn_gt`}
                                  >
                                    {title}
                                  </Nav.Link>
                                )}
                              </React.Fragment>
                            )
                          } else if (type === "list") {
                            return (
                              <Dropdown
                                key={`${title}-ndp`}
                                alignRight
                                className="megaMenuColumnbox"
                              >
                                <Dropdown.Toggle
                                  as={NavLink}
                                  href={"#"}
                                  className="text-white"
                                  onClick={() => clickHandler(title)}
                                  onMouseEnter={() => hoverHandler(title)}
                                >
                                  {title}
                                  <img
                                    style={{
                                      filter: "brightness(0) invert(1)",
                                    }}
                                    src={caretDown}
                                    decoding="async"
                                    loading="lazy"
                                    alt="caret down"
                                    className={styles.alignHeaderDropIcon}
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  renderOnMount={renderMenu}
                                  className={`ml-3`}
                                >
                                  <Row className={styles.dropMenu}>
                                    <Col xs={12}>
                                      {subItems?.map(subItem => (
                                        <div key={`${subItem?.title}-ndpi`}>
                                          <Dropdown.Item
                                            as={Link}
                                            to={`/${subItem?.url}/`}
                                            className="p-1 bg-white"
                                          >
                                            <Row className={styles.menuItem}>
                                              <Col xs={3} md={3}>
                                                <img
                                                  src={subItem?.icon?.url}
                                                  alt={subItem?.title}
                                                  decoding="async"
                                                  loading="lazy"
                                                />
                                              </Col>
                                              <Col
                                                xs={9}
                                                md={9}
                                                className="d-flex align-items-center "
                                              >
                                                <div className="d-flex ml-2">
                                                  {subItem?.title}
                                                </div>
                                              </Col>
                                            </Row>
                                          </Dropdown.Item>
                                          <Dropdown.Divider
                                            className={styles.divider}
                                          />
                                        </div>
                                      ))}
                                    </Col>
                                  </Row>
                                </Dropdown.Menu>
                              </Dropdown>
                            )
                          } else if (
                            type === "grid" &&
                            title.toLowerCase() !== "portfolio"
                          ) {
                            return (
                              <Dropdown
                                key={`${title}-ndp`}
                                alignRight
                                className="megaMenuColumnbox"
                              >
                                <Dropdown.Toggle
                                  as={NavLink}
                                  href={`/${url}/`}
                                  disabled={url === "#" ? true : false}
                                  className="text-white"
                                  id={`dropdown-autoclose-outside-${index}`}
                                  onMouseEnter={() => hoverHandler(title)}
                                >
                                  {title}
                                  <img
                                    style={{
                                      filter: "brightness(0) invert(1)",
                                    }}
                                    src={caretDown}
                                    decoding="async"
                                    loading="lazy"
                                    alt="caret down"
                                    className={styles.alignHeaderDropIcon}
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu renderOnMount={renderMenu}>
                                  <Row
                                    className={[
                                      styles.dropdownMulticolMenu,
                                      subItems?.length > 4
                                        ? styles.column3
                                        : styles.column2,
                                    ]}
                                    style={{
                                      maxHeight: 500,
                                      overflowY: "auto",
                                      position: `${
                                        subItems?.length > 4 ? "fixed" : "unset"
                                      }`,
                                      marginRight: `${
                                        subItems?.length > 4
                                          ? "auto"
                                          : title === "Company"
                                          ? "-105px"
                                          : "-220px"
                                      }`,
                                      marginTop: `${
                                        subItems?.length > 4 ? "auto" : "8px"
                                      }`,
                                    }}
                                  >
                                    {subItems?.map(subItem => (
                                      <Col
                                        lg={subItems.length > 4 ? 4 : 6}
                                        className={styles.megaMenuColumn}
                                        key={`${subItem.title}-ndpi`}
                                      >
                                        {subItem.url ===
                                        "https://involearn.io/" ? (
                                          <Dropdown.Item
                                            href={subItem.url}
                                            className={`container justify-content-start ${styles.megaMenuLink} ${styles.traningSection}`}
                                          >
                                            <img
                                              src={subItem?.icon?.sourceUrl}
                                              alt={subItem?.title}
                                              width={32}
                                              decoding="async"
                                              loading="lazy"
                                              style={{ float: "left" }}
                                            />

                                            <div className={`d-flex ml-2`}>
                                              {subItem.title}
                                            </div>
                                          </Dropdown.Item>
                                        ) : (
                                          <Dropdown.Item
                                            as={Link}
                                            to={
                                              url === "portfolio"
                                                ? `/${url}/${subItem.url}/`
                                                : `/${subItem.url}/`
                                            }
                                            className={`container justify-content-start ${styles.megaMenuLink}`}
                                          >
                                            <img
                                              src={subItem?.icon?.url}
                                              alt={subItem?.title}
                                              width={32}
                                              decoding="async"
                                              loading="lazy"
                                              style={{ float: "left" }}
                                            />
                                            <div className="d-flex ml-2">
                                              {subItem.title}
                                            </div>
                                          </Dropdown.Item>
                                        )}
                                        <Dropdown.Divider
                                          className={styles.divider}
                                        />
                                      </Col>
                                    ))}
                                    {button?.url !== undefined && (
                                      <Col
                                        lg={4}
                                        className={`justify-content-center d-flex pt-2 pt-md-1 ml-auto`}
                                      >
                                        <a
                                          href={`/${button?.url}/`}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <button
                                            className="btn_black_border font-weight-normal"
                                            style={{ fontSize: 13 }}
                                          >
                                            {button?.title}
                                            <img
                                              src={arrow}
                                              loading="lazy"
                                              alt="arrow"
                                            />
                                          </button>
                                        </a>
                                      </Col>
                                    )}
                                    {url ===
                                      "software-development-services" && (
                                      <div className={styles.blox}>
                                        {name.map((e, i) => (
                                          <Col
                                            lg={4}
                                            className={styles.outer}
                                            key={i}
                                          >
                                            <img
                                              src={e?.image}
                                              className={styles.bgImgs}
                                              alt="icon"
                                            />
                                            <div className={styles.invogames}>
                                              <p>{e?.title}</p>
                                              <a
                                                href={e?.link}
                                                className={styles.gamesBtn}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                Visit Website
                                                <img
                                                  src={e?.image2}
                                                  alt="icon"
                                                />
                                              </a>
                                            </div>
                                          </Col>
                                        ))}
                                      </div>
                                    )}
                                  </Row>
                                </Dropdown.Menu>
                              </Dropdown>
                            )
                          }
                        }
                      )}

                      <Nav.Link
                        as={Link}
                        to="/contact-us/"
                        className={`btn  px-3 ml-3 mt-2 mt-md-0 ${styles.contactLink}`}
                        id="header_contact_us_btn_gt"
                      >
                        Contact Us
                      </Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </React.Fragment>
              ) : (
                <MobileHeader items={items} />
              )
            ) : (
              <div
                id="main-navbar-nav"
                className={`ml-auto text-right flex-column align-items-end justify-content-end d-flex pt-2 pt-md-1`}
              >
                <a href="mailto:sales@invozone.com" className={`text-white`}>
                  sales@invozone.com
                </a>
                <a href="tel:+17542587812" className={`text-white`}>
                  +1 (754) 258-7812
                </a>
              </div>
            )}
          </div>
        </Navbar>
      </div>
    </header>
  )
}

export default Header
