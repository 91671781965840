// extracted by mini-css-extract-plugin
export var alignHeaderDropIcon = "Header-module--alignHeaderDropIcon--dc9c2";
export var alignHeaderDropIconList = "Header-module--alignHeaderDropIconList--39017";
export var bgImgs = "Header-module--bgImgs--d20c3";
export var blink = "Header-module--blink--307c9";
export var blox = "Header-module--blox--1af72";
export var column2 = "Header-module--column2--6d5de";
export var column3 = "Header-module--column3--78d0f";
export var contactLink = "Header-module--contactLink--2141f";
export var createBox = "Header-module--createBox--c8788";
export var cross = "Header-module--cross--6443a";
export var divider = "Header-module--divider--01de5";
export var dropMenu = "Header-module--dropMenu--ea3fd";
export var dropdownMulticolMenu = "Header-module--dropdownMulticolMenu--1a58e";
export var flexline = "Header-module--flexline--5887a";
export var gamesBtn = "Header-module--gamesBtn--a6d3e";
export var headerContainer = "Header-module--headerContainer--ab194";
export var invogames = "Header-module--invogames--27fcc";
export var leap = "Header-module--leap--b0626";
export var leapBanner = "Header-module--leapBanner--9345e";
export var lineBetween = "Header-module--lineBetween--9dc19";
export var mail = "Header-module--mail--936ba";
export var megaMenuColumn = "Header-module--megaMenuColumn--3729c";
export var megaMenuLink = "Header-module--megaMenuLink--86e9d";
export var menuItem = "Header-module--menuItem--e8505";
export var navBg = "Header-module--navBg--81e5e";
export var navMail = "Header-module--navMail--d856b";
export var navTop = "Header-module--navTop--8ee6d";
export var navbar = "Header-module--navbar--52903";
export var outer = "Header-module--outer--2a58e";
export var traningSection = "Header-module--traningSection--3e36b";